const localStorage = window.localStorage;

if ( ! process.env.REACT_APP_STRIPE_KEY ) {
	console.error( 'No API URL Set.' );
}
const apiUrl = process.env.REACT_APP_API_URL;

if ( ! process.env.REACT_APP_ENVIRONMENT ) {
	console.error( 'No Environment Set.' );
}

function auth( username, password, setUserId, setResponse ) {
	setResponse( false );
	fetch( `${apiUrl}kna/v1/profundo/authenticate?username=${username}&password=${password}`, {
		method: 'POST',
	} ).then( response => response.json() ).then( response => {
		if ( response.status ) {
			switch (response.status) {
				case 'invalid_credentials':
					setResponse( 'Feil telefonnummer eller passord' );
				break;
				default:
					setResponse( response.status + ': ' + response.message );
			}
			switch ( response.status ) {
				case 'success':
					if ( response.token ) {
						localStorage.setItem( 'kna-jwt', response.token );
						setUserId( parseJwt( response.token ).sub );
					}
					break;
				default:
					setUserId( false );
			}
		}
	} );
}

function parseJwt( token ) {
	var base64Url = token.split( '.' )[ 1 ];
	var base64 = base64Url.replace( /-/g, '+' ).replace( /_/g, '/' );
	var jsonPayload = decodeURIComponent( atob( base64 ).split( '' ).map( function( c ) {
		return '%' + ( '00' + c.charCodeAt( 0 ).toString( 16 ) ).slice( -2 );
	} ).join( '' ) );

	return JSON.parse( jsonPayload );
};

function reAuth() {
	return true;
}

function logout( setUserId ) {
	localStorage.removeItem( 'kna-jwt' );
	setUserId( false );
}

function apiPost( method, data ) {
	const jwt = localStorage.getItem( 'kna-jwt' );
	const JSONdata = JSON.stringify( data );
	return new Promise( resolve => {
		if ( jwt ) {
			fetch( `${apiUrl}kna/v1/profundo/${method}?data=${JSONdata}`, {
				method: 'POST',
				headers: {
					'Authorization': 'bearer ' + jwt,
				}
			} ).then( response => response.json() ).then( response => {
				resolve( response );
			} );
		} else {
			fetch( `${apiUrl}kna/v1/profundo/${method}?data=${JSONdata}`, {
				method: 'POST',
			} ).then( response => response.json() ).then( response => {
				resolve( response );
			} );
		}
	} );
}

function apiRelay( method, data ) {
	const jwt = localStorage.getItem( 'kna-jwt' );

	/**
	 * Note: Using test mode in Profundo's API can give unexpected results.
	 * For example, a call to the 'get-stripe-payment-id' endpoint with
	 * test_mode set to true, will actually change an unpaid membership to
	 * be marked as paid in the API. In the CRM it will still be unpaid,
	 * but all subsequent API calls, even with test mode off, will return
	 * the membership as paid.
	 */
	// if ( 'production' !== process.env.REACT_APP_ENVIRONMENT && 'member/get-stripe-payment-id' === method ) {
	// 	data['test_mode'] = true;
	// }

	const JSONdata = JSON.stringify( data );

	return new Promise( resolve => {
		if ( jwt ) {
			fetch( `${apiUrl}kna/v1/profundo/relay?method=${method}&data=${JSONdata}`, {
				method: 'POST',
				headers: {
					'Authorization': 'bearer ' + jwt,
				}
			} ).then( response => response.json() ).then( response => {
				resolve( response );
			} );
		} else {
			fetch( `${apiUrl}kna/v1/profundo/relay?method=${method}&data=${JSONdata}`, {
				method: 'POST',
			} ).then( response => response.json() ).then( response => {
				resolve( response );
			} );
		}
	} );
}

export {
	auth,
	reAuth,
	logout,
	apiPost,
	apiRelay,
	parseJwt
};
