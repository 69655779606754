import React from 'react';
import {
	Link,
} from 'react-router-dom';

import Header from 'components/Header';
import BackButton from 'components/Elements/BackButton';
import Notice from 'components/Elements/Notice';

import './style.scss';

function ChangePaymentMethod( props ) {

	const {
		hasPendingPayment
	} = props;

	return (
		<>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			<BackButton/>

			<div className="view">
				<h1>Endre betalingsmåte</h1>

				<p>
					Her kan du velge ønsket betalingsmåte, samt oppdatere eksisterende kortdetaljer om ønskelig.
				</p>

				{ hasPendingPayment &&
					<Notice
						heading="Betaling under behandling"
						type="warning"
					>
						<p>
						Du har en betaling under behandling, og kan derfor ikke endre betalingsmåte eller gjennomføre nye ordre enda.
						<br/>
						<br/>
						Dette kan i svært få tilfeller ta opp mot en time.
						</p>
					</Notice>
				}

				{ ! hasPendingPayment &&
					<>
					<Link to="/betaling/kort" className="block-link">
						Betal med kort
					</Link>
					</>
				}
			</div>
		</>
	);
}

export default ChangePaymentMethod;