import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
	CardElement,
	Elements,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import Header from 'components/Header';
import './style.scss';
import {
	apiPost,
	apiRelay,
} from 'api';
import BackButton from '../Elements/BackButton';
import Notice from '../Elements/Notice';

if ( ! process.env.REACT_APP_STRIPE_KEY ) {
	console.error( 'No Stripe Public Key Set.' );
}
const stripePromise = loadStripe( process.env.REACT_APP_STRIPE_KEY );

const CheckoutPayment = ({
	hasPaidMembership,
	hasPendingPayment,
	membershipData,
	membershipsPayment,
	setLoading,
	user,
	setDone,
}) => {
	const [cardError, setCardError] = useState(false);
	const [modal, setModal] = useState(0);
	const [performingPayment, setPerformingPayment] = useState(false);

	const stripe = useStripe();
	const elements = useElements();

	let payButtonLabel = 'Fullfør betaling';

	const handleSubmit = async ( event ) => {
		event.preventDefault();

		setPerformingPayment(true);
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement( CardElement )
		});
		if ( error ) {
			setPerformingPayment(false);
			console.log( 'error', error );
			if ( error.type === 'validation_error' ) {
				setCardError(error.message);
			}
		} else {
			const response = await apiRelay( 'member/get-stripe-payment-id', membershipData );

			if ( response && response.status === 'ok' ) {
				setPerformingPayment(false);
				const paymentData = { 'payment_method': paymentMethod.id, 'payment_id': response.data.payment_id, 'email': user.email, 'subscriptions': membershipsPayment };
				const paymentResponse = await apiPost( 'stripe-payment', paymentData );
				if (paymentResponse.id) {
					switch (paymentResponse.status) {
						case 'incomplete':
							// Confirm payment.
							const {client_secret, status} = paymentResponse.latest_invoice.payment_intent;
							if (status === 'requires_payment_method') {
								console.log('payment failed');
								setCardError('Betalingen feilet, prøv igen med ett nytt kort.');
								setLoading(false);
							} else {
								stripe.confirmCardPayment(client_secret).then(function(result) {
								if (paymentResponse.error) {
									console.log('error', paymentResponse.error);
									alert('Error, din betaling kunne ikke gjennomføres. Følgende feilmelding ble returnet fra din kortbehandler:' + paymentResponse.error );
									setLoading(false);
								} else {
									setDone(true);
									setLoading(false);
								}
								});
							}
							break;
						case 'active':
							setLoading(false);
							setDone(true);
							break;
						default:
							setLoading(false);
							console.log(paymentResponse.status, paymentResponse);
							alert('Error, din betaling kunne ikke gjennomføres. Følgende feilmelding ble returnet fra din kortbehandler:' + paymentResponse.status + ' ' + JSON.stringify( paymentResponse ) );
					}
				} else {
					setLoading(false);
					console.log('error', paymentResponse);
					alert('Error, din betaling kunne ikke gjennomføres. Følgende feilmelding ble returnet fra din kortbehandler:' + JSON.stringify( paymentResponse ) );
				}
			} else {
				setPerformingPayment(false);
				setCardError('Betalingen feilet: ' + response.status);
				setLoading(false);
			}
		}
	};

	if ( performingPayment ) {
		payButtonLabel = 'Betaling behandles...';
	} else {
		if ( hasPaidMembership ) {
			payButtonLabel = 'Endre betalingskort';
		}
		if ( hasPendingPayment ) {
			setPerformingPayment(true);
			payButtonLabel = 'Betaling behandles...';
		}
	}

	return (
		<>
		<div className={'block payment' + (cardError ? ' error' : '')}>
			<div className="payment-header" />
			<CardElement onChange={ () => setCardError(false) } />
			{cardError &&
				<span className="error">{cardError}</span>
			}
		</div>
		<div className="send">
			<button onClick={handleSubmit} className='wp-block-button__link' disabled={performingPayment}>
				{payButtonLabel}
			</button>
		</div>
		{(modal !== 0) &&
			<div className="info-modal">
				<div className="info-modal-inner">
					{(modal === 1) &&
						<h2>Markedsførings betingelser</h2>
					}
					{(modal === 2) &&
						<h2>Brukeravtale</h2>
					}
					<p>Text goes here</p>
					<div className="controls">
						<button className="wp-block-button__link" onClick={()=>setModal(0)}>Lukk</button>
					</div>
				</div>
			</div>
		}
		</>
	);
};

function PayWall(props) {
	const {
		member,
		memberships,
		membershipsData,
		user,
		hasPaidMembership,
		hasPendingPayment
	} = props;

	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);

	if ( ! memberships.own ) {
		return null;
	}

	const membershipData = {
		payer_name_id: memberships.own.name_id,
		memberships: []
	};

	const membershipsPayment = [];

	const getSummary = () => {
		const summary = [];
		let sum = 0;

		for (var i = 0; i < memberships.own.memberships.length; i++) {
			const membership = memberships.own.memberships[i];
			if (!membership.paid) {
				if (membershipsData[membership.type_id]) {
					const price = membershipsData[membership.type_id].price;
					sum += parseInt(price);
					summary.push(
						<li>{membership.type_name} {price},-</li>
					);
					membershipsPayment.push(membership.type_id);
					membershipData.memberships.push({ name_id: memberships.own.name_id, membership_id: membership.membership_id });
				}
			}
		}
		for (i = 0; i < memberships.family.length; i++) {
			for (var j = 0; j < memberships.family[i].memberships.length; j++) {
				const membership = memberships.family[i].memberships[j];
				if(!membership.paid) {
					const price = membershipsData[membership.type_id].price;
					sum += parseInt(price);
					summary.push(
						<li>{membership.type_name} {price},-</li>
					);
					membershipsPayment.push(membership.type_id);
					membershipData.memberships.push({ name_id: memberships.family[i].name_id, membership_id: membership.membership_id });
				}
			}
		}
		return (
			<ul>
				{summary}
				<li className="total">Total: {sum},- per år</li>
			</ul>
		);
	}

	return (
		<div>
			<Header navigation="menu">
				<div className="logo" />
			</Header>

			{ member &&
				<BackButton/>
			}

			<div className={"view" + (loading ? ' loading' : '') + (done ? ' done' : '')}>
				{ hasPaidMembership &&
					<>
						<h1>Endre kortbetaling</h1>

						<p>Her kan du erstatte ditt eksisterende betalingskort dersom ditt eksisterende kort f.eks. snart fornyes.</p>
					</>
				}
				{ hasPendingPayment &&
					<Notice
						heading="Betaling under behandling"
						type="warning"
					>
						<p>
							Du har en betaling under behandling, og kan derfor ikke endre betalingsmåte eller gjennomføre nye ordre enda.
							<br/>
							<br/>
							Dette kan i svært få tilfeller ta opp mot en time.
						</p>
					</Notice>
				}
				{ ! hasPaidMembership && ! hasPendingPayment &&
					<>
						<h1>Fullfør betaling</h1>
						<p>Et eller flere av dine KNA medlemskap har ikke blitt betalt.</p>
						<p>Dette kan blant annet bero på at betalingen feilet ved registrering, eller at betalingsmetoden har utløpt. Fullfør betalingen nedenfor for å aktivere medlemskapet.</p>
					</>
				}
				<div className="payment-wrap">
					{ ! hasPaidMembership &&
						getSummary()
					}
					<Elements stripe={stripePromise}>
						<CheckoutPayment
							setLoading={setLoading}
							user={user}
							hasPaidMembership={hasPaidMembership}
							hasPendingPayment={hasPendingPayment}
							membershipsPayment={membershipsPayment}
							membershipData={membershipData}
							setDone={setDone}
						/>
					</Elements>
				</div>
			</div>
		</div>

	);
}

export default PayWall;
